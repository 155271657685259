<i18n src="@i18n/drone/part.th.yaml" locale="th" lang="yaml"/>
<i18n src="@i18n/drone/drone_model.th.yaml" locale="th" lang="yaml"/>
<i18n locale="th" lang="yaml" >
page.section.initial : "อะไหล่ตั้งต้น"
page.section.optional : "อะไหล่เสริม"

button.manage_parts : "แก้ไขอะไหล่"
part.field.has_serial_no : "Serial No"
</i18n>

<template>
	<div class="page page-padding">
		<a-button
				v-if="canManageParts"
				class="btn-page-action"
				type="primary"
				icon="tool"
				@click="clickManageParts">
				{{$t('button.manage_parts')}}
		</a-button>

		<div v-for="section in displayList" :key="section.key" style="margin-bottom:32px">
			<div class="page-section-title">
				{{section.title}}
			</div>
			<a-table
				:loading="loading"
				bordered class="mytable mytable-scroll model-part-table"
				:row-key="record => record.part_id"
				:data-source="section.value"
				:pagination="false"
				size="small">
				<a-table-column
					align="center"
					:width="40">
					<template slot-scope="text,record,index">
						{{index + 1}}.
					</template>
				</a-table-column>
				<a-table-column
					:sorter="tableSortSKU"
					:title="$t('part.field.sku')" data-index='part.sku'
					:width="150">
					<template slot-scope="text,record">
						<my-router-link name="part/view" :param-value="record.part_id" new-window auth-action="read" auth-module="part">
							{{text}}
						</my-router-link>
					</template>
				</a-table-column>
				<a-table-column
					:title="$t('common.table.column.detail')" data-index="part.name"
					:sorter="tableSortName">
					<template slot-scope="text,record">
						<MyPopover overlay-class-name="mycard-popover"
							 :destroy-tooltip-on-hide="true">
							<template slot="content">
								<PartLiteCard :part="record.part" size="small"  link-target="_blank" />
							</template>
							{{record.part.name}}
						</MyPopover>
					</template>
				</a-table-column>
				<a-table-column
					:title="$t('part.field.part_type')" data-index='part.part_type'
					:width="120"
					:sorter="tableSortPartType">
					<template slot-scope="text">
						{{$tenum('part_type',text)}}
					</template>
				</a-table-column>
				<a-table-column
					:title="$t('part.field.has_serial_no')" data-index='part.has_serial_no'
					align="center"
					:width="75">
					<template slot-scope="hasSerialNo">
						<a-icon v-if="hasSerialNo" type="check" class="text-success"/>
					</template>
				</a-table-column>

				<a-table-column
					:title="$t('model_part.field.total')"
					data-index="total"
					:sorter="tableSortTotal"
					:width="120"
					align="right">
					<template slot-scope="total">
						{{$tc_my('part.number.display',total)}}
					</template>
				</a-table-column>

				<a-table-column
					:title="$t('model_part.field.remark')"
					data-index="remark"
					:width="50"
					align="center">
					<template slot-scope="remark">
						<MyTooltip v-if="$notEmpty(remark)"
							:destroy-tooltip-on-hide="true">
							<div slot="title" v-trim class="multiline">
								{{remark}}
							</div>
							<a-icon type="message"/>
						</MyTooltip>
					</template>
				</a-table-column>
			</a-table>
		</div>

	</div>
</template>

<script>
import PageMixin from "@mixins/PageMixin.vue"
import SortModelPartMixin from '@mixins/drone/SortModelPartMixin.vue'
import axios from "axios"
import Vue from "vue"
import { mapGetters } from 'vuex'
import {Table} from "ant-design-vue"
import MyTooltip from "@components/common/MyTooltip.vue"
import MyPopover from "@components/common/MyPopover.vue"
import PartLiteCard from "@components/part/PartLiteCard.vue"

export default {
	components : {
		PartLiteCard , MyPopover , MyTooltip,
		"a-table" : Table, "a-table-column" : Table.Column
	} ,
	mixins : [PageMixin,SortModelPartMixin] ,
	props : {
		model : {
			type: Object ,
			default : () => []
		} ,
		numLots : {
			type : Number,
			default : 0
		} ,
		numDrones : {
			type : Number,
			default : 0,
		}
	} ,
	data() {
		return {
			loading : false,
			modelParts : []
		}
	} ,
	computed : {
		...mapGetters('drone',['getPartById']) ,
		canManageParts() {
			return this.$authorize('update','droneModel');
		} ,
		displayList(){
			return [
				{
					key : 'initial' ,
					title : this.$t('page.section.initial') ,
					value : this.initialModelParts
				} ,
				{
					key : 'optional' ,
					title : this.$t('page.section.optional') ,
					value : this.optionalModelParts
				} ,
			]
		} ,
		initialModelParts() {
			return this.modelParts.filter((part) => part.type == 'initial')
		} ,
		optionalModelParts() {
			return this.modelParts.filter((part) => part.type == 'optional')
		}
	} ,
	watch: {
		model : {
			handler() {
				this.fetchData()
			} ,
			deep: true
		}
	} ,
	mounted() {
		this.fetchData()
	} ,
	methods : {
		clickManageParts() {
			this.$router.push({
				name:'drone_model/parts_manage',params:{id : this.model.id}
			})
		} ,
		fetchData() {
			if (!this.model || !this.model.id)
				return
			this.showPageLoading(true)
			this.loading = true
			axios.get("/api/drone-models/"+this.model.id+"/parts").then((response) => {
				this.modelParts = response.data.data.modelParts;
				for(const modelPart of this.modelParts) {
					Vue.set(modelPart,"part",this.getPartById(modelPart.part_id))
				}
			}).catch((error) => {
				this.fetchError(error);
			}).finally(()=>{
				this.hidePageLoading()
				this.loading = false
			})
		} ,
	}
}
</script>
